import client from '../../apollo-client';
import {
  CONSUME_REPORT,
  CREATE_REPORT,
  GET_PREVIEW_REPORT,
  TEST_GENERATE_PDF,
} from './pdfReport.graphql';

export async function createReportService(input: any): Promise<Report> {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_REPORT,
      variables: {
        input: input,
      },
    });

    return data.createReport;
  } catch (error) {
    console.error('An error occurred while creating the report:', error);
    throw error;
  }
}

export async function getPreviewReportService(input: any): Promise<any> {
  try {
    const { data } = await client.mutate({
      mutation: GET_PREVIEW_REPORT,
      variables: {
        input: input,
      },
    });

    return data.getPreviewReport;
  } catch (error) {
    console.error('An error occurred while getting the preview report:', error);
    throw error;
  }
}

export async function consumeReportService(): Promise<any> {
  try {
    const { data } = await client.mutate({
      mutation: CONSUME_REPORT
    });

    return data.consumeReport;
  } catch (error) {
    console.error('Ann error occured while consuming the report quota:', error);
    throw error;
  }
}

export async function testGeneratePDFService(): Promise<any> {
  try {
    const { data } = await client.mutate({
      mutation: TEST_GENERATE_PDF,
    });

    return data.testGeneratePDF;
  } catch (error) {
    console.error('An error occurred while generating the PDF:', error);
    throw error;
  }
}
