import React, { createContext, useState, useContext } from 'react';

type SelectedFilter = {
  orderByField: string;
  orderByOrder?: 'ASC' | 'DESC';
};

const FilterContext = createContext<{
  selectedFilter: SelectedFilter;
  setSelectedFilter: React.Dispatch<React.SetStateAction<SelectedFilter>>;
} | null>(null);

// export const FilterProvider: React.FC = ({ children }) => {
export const FilterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>({
    orderByField: 'created_at', // Default field
    orderByOrder: 'ASC', // Default order
  });

  return (
    <FilterContext.Provider value={{ selectedFilter, setSelectedFilter }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }
  return context;
};
