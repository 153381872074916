import { gql } from '@apollo/client';

export const CREATE_REPORT = gql`
  mutation CreateReport($input: CreateReportInput!) {
    createReport(input: $input) {
      reportId
    }
  }
`;

export const GET_PREVIEW_REPORT = gql`
  mutation GetPreviewReport($input: CreateReportDto!) {
    getPreviewReport(input: $input) {
      pdf
    }
  }
`;

export const TEST_GENERATE_PDF = gql`
  mutation TestGeneratePDF {
    testGeneratePDF
  }
`;

export const CONSUME_REPORT = gql`
  mutation consumeReport {
    consumeReport {
      access_token
      refresh_token
    }
  }
`;