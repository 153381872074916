import { SensorFamilyEnum } from '../enums/feelbat.enum';
import { Project, ProjectWithCounts, Sensor } from '../types/feelbat';

const isValidSensorFamily = (
  family: string
): family is keyof typeof SensorFamilyEnum => {
  return Object.keys(SensorFamilyEnum).includes(family);
};

const shouldCountSensor = (sensor: Sensor): boolean => {
  if (isValidSensorFamily(sensor.family)) {
    const sensorFamily = SensorFamilyEnum[sensor.family];

    return (
      (sensorFamily as SensorFamilyEnum) === SensorFamilyEnum.JC1 ||
      (sensorFamily as SensorFamilyEnum) === SensorFamilyEnum.JC2 ||
      ((sensorFamily as SensorFamilyEnum) !== SensorFamilyEnum.JC1 &&
        (sensorFamily as SensorFamilyEnum) !== SensorFamilyEnum.JC2 &&
        sensor.active)
    );
  }
  return false;
};

export const getTModuleAndSensorsCount = (
  data: Project[]
): ProjectWithCounts[] => {
  return data.map((project) => {
    let tmoduleCount = 0;
    let activeSensorsCount = 0;

    if (project.tmodules) {
      tmoduleCount = project.tmodules.length;
      activeSensorsCount = project.tmodules.reduce(
        (acc, tmodule) =>
          acc +
          (tmodule.sensors
            ? tmodule.sensors.filter(shouldCountSensor).length
            : 0),
        0
      );
    }

    const projectWithCounts: ProjectWithCounts = {
      ...project,
      tmoduleCount,
      activeSensorsCount,
    };

    return projectWithCounts;
  });
};

export const getTModuleAndSensorsCountByProject = (project: Project) => {
  const tmoduleCount = project?.tmodules?.length;

  const activeSensorsCount = project?.tmodules?.reduce((count, tmodule) => {
    return count + tmodule.sensors.filter(shouldCountSensor).length;
  }, 0);

  return { tmoduleCount, activeSensorsCount };
};

export const camelToSnakeCaseObj = (
  obj: Record<string, any>
): Record<string, any> => {
  return Object.keys(obj).reduce((result: Record<string, any>, key) => {
    const newKey = key.replace(
      /[A-Z]/g,
      (letter) => `_${letter.toLowerCase()}`
    );
    result[newKey] = obj[key];
    return result;
  }, {});
};

/**
 * Sorts an array of objects by a specified field.
 * @param array The array to sort.
 * @param field The field by which to sort the objects.
 * @returns A new array sorted by the specified field.
 */
export const sortItemsBy = (array: any[] | undefined, field: any): any[] => {
  if (array === undefined) return [];
  return Array.from(array).sort((a: any, b: any) => {
    const [nameA, numA] = a[field].split(RegExp(/[_-]/));
    const [nameB, numB] = b[field].split(RegExp(/[_-]/));

    if (nameA === nameB) {
      return parseInt(numA) - parseInt(numB);
    }

    return nameA.localeCompare(nameB);
  });
};

/**
 * Constrains a number within a specified range.
 * @param value The value to be constrained.
 * @param min The minimum value of the range.
 * @param max The maximum value of the range.
 * @returns The constrained value within the specified range.
 */
export const rangeNumber = (
  value: number,
  min: number,
  max: number
): number => {
  if (min < value && value < max) {
    return value;
  }
  if (min > value) {
    return min;
  }
  return max;
};

export const DECIMALS_LENGTH = 2;

export const formatNumber = (
  number: Number | undefined
): string | undefined => {
  return number?.toFixed(DECIMALS_LENGTH);
};
