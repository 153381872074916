import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthState } from '../../types/auth';
import { loginUser, refreshToken, consumeReport } from './auth.thunk';

const initialState: AuthState = {
  isLoading: false,
  accessToken: null,
  refreshToken: null,
  userId: null,
  role: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.isLoading = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.userId = null;
      state.role = null;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        loginUser.fulfilled,
        (
          state,
          action: PayloadAction<{ access_token: string; refresh_token: string }>
        ) => {
          const base64Payload = action.payload.access_token.split('.')[1];
          const decodedPayload = JSON.parse(atob(base64Payload));

          state.isLoading = false;
          state.accessToken = action.payload.access_token;
          state.refreshToken = action.payload.refresh_token;
          state.userId = decodedPayload.userId;
          state.role = decodedPayload.role;
        }
      )

      .addCase(
        refreshToken.fulfilled,
        (
          state,
          action: PayloadAction<{ access_token: string; refresh_token: string }>
        ) => {
          state.accessToken = action.payload.access_token;
          state.refreshToken = action.payload.refresh_token;
        }
      )

      .addCase(
        consumeReport.fulfilled,
        (
          state,
          action: PayloadAction<{ access_token: string; refresh_token: string }>
        ) => {
          state.accessToken = action.payload.access_token;
          state.refreshToken = action.payload.refresh_token;
        }
      )

      .addCase(refreshToken.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { logoutUser, setLoading, setAccessToken } = authSlice.actions;
export const resetStore = () => ({ type: 'RESET_STORE' });

export default authSlice.reducer;
