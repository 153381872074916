import { lazy } from 'react';
import { RoleIdEnum } from '../types/auth';

export interface RouteParams {
  [key: string]: string | undefined;
}

export interface Route {
  path: string;
  name?: string;
  element: ReturnType<typeof lazy>;
  isProtected?: boolean;
  requiredRoles?: RoleIdEnum[];
}

export const routes: Route[] = [
  {
    path: '/login',
    name: 'login',
    element: lazy(() => import('../../components/pages/loginPage/LoginPage')),
  },
  {
    path: '/reset-password-request',
    name: 'Reset password request',
    element: lazy(
      () =>
        import(
          '../../components/pages/PasswordResetRequestPage/PasswordResetRequestPage'
        )
    ),
  },
  {
    path: '/projects',
    name: 'Projects',
    element: lazy(
      () =>
        import('../../components/pages/DetailedProjectPage/DetailedProjectPage')
    ),
    isProtected: true,
    requiredRoles: [RoleIdEnum.USER, RoleIdEnum.ADMIN],
  },
  {
    path: '/reset-password',
    name: 'Reset password',
    element: lazy(
      () => import('../../components/pages/PasswordResetPage/PasswordResetPage')
    ),
  },
  {
    path: '/projects/:id',
    name: 'Project Detail',
    element: lazy(
      () => import('../../components/pages/ProjectDetailPage/ProjectDetailPage')
    ),
    isProtected: true,
    requiredRoles: [RoleIdEnum.USER, RoleIdEnum.ADMIN],
  },
  {
    path: '/projects/:id/report',
    name: 'Report configuration',
    element: lazy(
      () =>
        import(
          '../../components/pages/ReportConfigurationPage/ReportConfigurationPage'
        )
    ),
    isProtected: true,
    requiredRoles: [RoleIdEnum.USER, RoleIdEnum.ADMIN],
  },
  {
    path: '/sensors-list',
    name: 'Sensors List',
    element: lazy(
      () => import('../../components/pages/SensorsListPage/SensorsListPage')
    ),
    isProtected: true,
    requiredRoles: [RoleIdEnum.USER, RoleIdEnum.ADMIN],
  },
  {
    path: '/projects/:projectId/sensor-List',
    name: 'Sensors',
    element: lazy(
      () => import('../../components/pages/SensorsListPage/SensorsListPage')
    ),
    isProtected: true,
    requiredRoles: [RoleIdEnum.USER, RoleIdEnum.ADMIN],
  },
  {
    path: '/projects/:projectId/sensor-List/:sensorId',
    name: 'Sensor',
    element: lazy(
      () => import('../../components/pages/SensorDetailPage/SensorDetailPage')
    ),
    isProtected: true,
    requiredRoles: [RoleIdEnum.USER, RoleIdEnum.ADMIN],
  },
  {
    path: '/sensor-List/:sensorId',
    name: 'Sensor2',
    element: lazy(
      () => import('../../components/pages/SensorDetailPage/SensorDetailPage')
    ),
    isProtected: true,
    requiredRoles: [RoleIdEnum.USER, RoleIdEnum.ADMIN],
  },
  {
    path: '/sensors-list/:id',
    name: 'Sensor Detail',
    element: lazy(
      () => import('../../components/pages/SensorDetailPage/SensorDetailPage')
    ),
    isProtected: true,
    requiredRoles: [RoleIdEnum.USER, RoleIdEnum.ADMIN],
  },
  {
    path: '/profile',
    name: 'Profile',
    element: lazy(
      () => import('../../components/pages/ProfilePage/ProfilePage')
    ),
    isProtected: true,
    requiredRoles: [RoleIdEnum.USER, RoleIdEnum.ADMIN],
  },
  {
    path: '/profile/leave',
    name: 'User Leave',
    element: lazy(() => import('../../components/pages/UserLeave/UserLeave')),
    isProtected: true,
    requiredRoles: [RoleIdEnum.USER, RoleIdEnum.ADMIN],
  },
  {
    path: '/verify-email',
    name: 'Email Verification',
    element: lazy(
      () =>
        import(
          '../../components/pages/EmailConfirmationPage/EmailConfirmationPage'
        )
    ),
    isProtected: false,
  },
  {
    path: '/admin/buying-code',
    name: 'Buying Code',
    element: lazy(
      () => import('../../components/pages/BuyingCodePage/BuyingCodePage')
    ),
    isProtected: true,
    requiredRoles: [RoleIdEnum.ADMIN],
  },
  {
    path: '/admin/users',
    name: 'Users',
    element: lazy(() => import('../../components/pages/UsersPage/UsersPage')),
    isProtected: true,
    requiredRoles: [RoleIdEnum.ADMIN],
  },
];
