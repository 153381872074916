export enum SensorFamilyEnum {
  DELTR = 'DELTA R',
  DELTA = 'DELTA L+',
  DELTX = 'DELTA X',
  DELTXP = 'DELTA XP',
  DELTX_L100 = 'DELTA X_L100',
  DELTX_H = 'DELTA X_H',
  DELHM = 'DELTA HM',
  JC1 = 'Jauge - JC1',
  JC2 = 'Jauge - JC2',
}

export enum SensorRawFamilyEnum {
  DELTR = 'DELTR',
  DELTA = 'DELTA',
  DELTX = 'DELTX',
  DELTXP = 'DELTXP',
  DELTX_L100 = 'DELTX_L100',
  DELTX_H = 'DELTX_H',
  DELHM = 'DELHM',
  JC1 = 'JC1',
  JC2 = 'JC2',
}

export enum MeasureTypeEnum {
  ANGLEY = 'ANGLEY',
  ANGLEX = 'ANGLEX',
  CUTTING = 'CUTTING',
  MISMATCH = 'MISMATCH',
  CRACKMOVEMENT = 'CRACKMOVEMENT',
  TEMPERATURE = 'TEMPERATURE',
  MEMORY_POINTER = 'MEMORY_POINTER',
  WALL_TEMPERATURE = 'WALL_TEMPERATURE',
  WALL_HUMIDITY = 'WALL_HUMIDITY',
  HUMIDITY = 'HUMIDITY',
  AIR_QUALITY = 'AIR_QUALITY',
  CO2 = 'CO2',
  PRESSURE = 'PRESSURE',
  GROUND_WATER_LEVEL = 'GROUND_WATER_LEVEL',
}

/**
 * Enum representing reduced sensor names.
 */
export enum SensorReducedName {
  DELTR = 'DR',
  DELTA = 'DL+',
  DELTX = 'DX',
  DELTXP = 'DXP',
  DELTX_L100 = 'L100',
  DELTX_H = 'DXH',
  DELHM = 'DHM',
  JC1 = 'JC1',
  JC2 = 'JC2',
}
