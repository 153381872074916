import { isNil } from 'lodash';
import client from '../../apollo-client';
import { MeasureTypeEnum } from '../../enums/feelbat.enum';
import { SensorFilter } from '../../types/feelbat';
import {
  GET_METRICS_BY_SENSOR_ID,
  GET_SENSORS_IN_STOCK_AND_PROJECT,
  FIND_ALL_USER_SENSOR,
  GET_SENSOR_BY_ID,
  GET_SENSOR_COUNT_BY_FAMILY,
  UPDATE_SENSOR,
  GET_METRICS_BY_SENSOR_ID_LIST,
  RENEW_SIGFOX_SUBSCRIPTION,
  CREATE_STARTING_CURVE,
  CLEAR_STARTING_CURVE,
} from './sensors.graphql';

export interface CreateSensorInput {
  measurement_interval?: number;
  sensor_type: string;
  image?: string;
  full_memory_handler_type?: number;
  vibration_alerte_type?: number;
  memory?: number;
  family: string;
  version_firmware?: string;
  thresholds_active_type?: number;
  id_device?: string;
  active: boolean;
  optional_name?: string;
  sigfox?: any; // Define the correct type
  project?: any; // Define the correct type
  module?: any; // Define the correct type
  existing?: any; // Define the correct type
}

export async function getSensorCountByFamilyService(): Promise<any> {
  try {
    const { data } = await client.query({
      query: GET_SENSOR_COUNT_BY_FAMILY,
    });

    return data.sensorCountByFamily;
  } catch (error) {
    console.error(
      'An error occurred while fetching sensor count by family:',
      error
    );
    throw error;
  }
}

export async function findAllUserSensorService(
  sensorFilter?: SensorFilter
): Promise<any> {
  try {
    const { data } = await client.query({
      query: FIND_ALL_USER_SENSOR,
      variables: !isNil(sensorFilter) ? { sensorFilter } : {},
    });

    return data.findAllUserSensor;
  } catch (error) {
    console.error("An error occurred while user's sensors:", error);
    throw error;
  }
}

export async function getSensorsInStockAndProjectService(): Promise<any> {
  try {
    const { data } = await client.query({
      query: GET_SENSORS_IN_STOCK_AND_PROJECT,
    });

    return data.sensorInStock;
  } catch (error) {
    console.error(
      'An error occurred while fetching sensors in stock and project:',
      error
    );
    throw error;
  }
}

export async function updateSensorService(
  updateSensorInput: any
): Promise<any> {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_SENSOR,
      variables: { updateSensorInput },
    });

    return data.updateSensor;
  } catch (error) {
    console.error('An error occurred while updating the sensor:', error);
    throw error;
  }
}

export async function getSensorByIdService(id: string): Promise<any> {
  try {
    const { data } = await client.query({
      query: GET_SENSOR_BY_ID,
      variables: { id },
    });

    return data.sensor;
  } catch (error) {
    console.error('An error occurred while fetching the sensor:', error);
    throw error;
  }
}

export async function getMetricsBySensorIdService(
  sensorId: string,
  startRange?: string,
  endRange?: string,
  fieldType?: MeasureTypeEnum[]
): Promise<any> {
  try {
    const { data } = await client.query({
      query: GET_METRICS_BY_SENSOR_ID,
      variables: { sensorId, startRange, endRange, fieldType },
    });

    return data.getMetricsBySensorId;
  } catch (error) {
    console.error(
      'An error occurred while fetching metrics by sensor ID:',
      error
    );
    throw error;
  }
}

export async function getMetricsBySensorsIdListService(
  sensorIdList: string[],
  startRange?: string | null,
  endRange?: string,
  fieldType?: MeasureTypeEnum[]
): Promise<any> {
  try {
    const getMetricsBySensorsIdListInput = {
      sensorIdList,
      startRange,
      endRange,
      fieldType,
    };

    const { data } = await client.query({
      query: GET_METRICS_BY_SENSOR_ID_LIST,
      variables: { getMetricsBySensorsIdListInput },
      fetchPolicy: 'network-only',
    });

    return data.getMetricsBySensorsIdList;
  } catch (error) {
    console.error(
      'An error occurred while fetching metrics by sensor ID list:',
      error
    );
  }
}

/**
 * Renews the SIGFOX subscription for a given sensor.
 *
 * This function sends a mutation request to the GraphQL server to renew
 * the SIGFOX subscription for the sensor with the provided SIGFOX ID.
 *
 * @param {string} sigfoxId - The SIGFOX ID of the sensor for which to renew the subscription.
 * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the subscription was successfully renewed.
 * @throws Will throw an error if the renewal fails.
 */
export async function renewSigfoxSubscriptionService(
  sigfoxId: string
): Promise<boolean> {
  try {
    const { data } = await client.mutate({
      mutation: RENEW_SIGFOX_SUBSCRIPTION,
      variables: { sigfoxId },
    });

    return data.restartAndActivateDevice;
  } catch (error) {
    console.error(
      `An error occurred while renewal the SIGFOX subscription for the sensor with sigfox id ${sigfoxId}:`,
      error
    );
    throw error;
  }
}

export async function createStartingCurve(
  sensorId: string,
  startAt: Date
): Promise<any> {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_STARTING_CURVE,
      variables: {
        createCurveFittingInput: { sensor_id: sensorId, start_at: startAt },
      },
    });

    return data.createStartingCurve;
  } catch (error) {
    console.error('An error occurred while updating the sensor:', error);
    throw error;
  }
}

export async function clearStartingCurve(sensorId: string): Promise<any> {
  try {
    const { data } = await client.mutate({
      mutation: CLEAR_STARTING_CURVE,
      variables: {
        clearStartingCurveInput: { sensor_id: sensorId },
      },
    });

    return data.clearStartingCurve;
  } catch (error) {
    console.error('An error occurred while updating the sensor:', error);
    throw error;
  }
}
